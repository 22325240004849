import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/services"
import ServicesCmp from "../components/services"
import { graphql } from "gatsby"

export default function Personal({ data }) {
  const { barText } = data.takeshape.getTopBar
  const footer = data.takeshape.getContactUsPage
  const { title, subtitle, list } = data.takeshape.getServicesPage
  console.log({ list })
  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Services" />
      <HeroPages title={title} text={subtitle} image={<Image />} />
      <div className="container mx-auto px-5 grid md:grid-cols-2 gap-10 md:mt-16 md:mb-24 mb-10">
        {list.map((item, index) => (
          <ServicesCmp key={index} item={item} serviceType="Personal" />
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getServicesPage(locale: $locale) {
        title
        subtitle
        list {
          options {
            timeAndPrice
          }
          service {
            name
            description
            category {
              singleLine
            }
          }
        }
      }
      getContactUsPage {
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
